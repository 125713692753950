// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-formal-js": () => import("./../src/pages/formal.js" /* webpackChunkName: "component---src-pages-formal-js" */),
  "component---src-pages-header-js": () => import("./../src/pages/header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-homophone-js": () => import("./../src/pages/homophone.js" /* webpackChunkName: "component---src-pages-homophone-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-menu-js": () => import("./../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-rightcolumn-js": () => import("./../src/pages/rightcolumn.js" /* webpackChunkName: "component---src-pages-rightcolumn-js" */),
  "component---src-pages-vietnamese-js": () => import("./../src/pages/vietnamese.js" /* webpackChunkName: "component---src-pages-vietnamese-js" */)
}

